import { Box, Typography } from "@material-ui/core"
import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Fade from "react-reveal/Fade"

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    maxWidth: "1200px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    margin: "96px 48px",
    gap: "32px",
    [theme.breakpoints.down("sm")]: {
      gap: "32px",
      flexDirection: "column",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "48px 24px",
    },
  },
  videoReel: {
    filter:
      "drop-shadow(0px 0px 5px rgba(243, 154, 112, 0.8)) drop-shadow(0px 0px 85px rgba(242, 101, 34, 0.5))",
    borderRadius: "4px",
  },
  backgroundBlur: {
    position: "absolute",
    width: "604px",
    height: "604px",
    background: "rgba(242, 101, 34, 0.66)",
    filter: "blur(177.299px)",
  },
  textWrapper: {
    zIndex: "2",
    width: "40%",
    maxWidth: "420px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "initial",
    },
  },
  videoWrapper: {
    width: "60%",
    padding: "128px 0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "0",
    },
  },
  title: {
    paddingBottom: "32px",
    fontSize: "56px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "36px",
    },
  },
  paragraph: {
    color: "#fff",
  },
}))

const VideoReel = ({ data }) => {
  const classes = useStyles()

  return (
    <Fade>
      <Box
        display="flex"
        position="relative"
        alignItems="center"
        justifyContent="center"
        overflow="hidden"
      >
        <Box
          className={classes.backgroundBlur}
          style={{ right: "-160px", bottom: "-120px" }}
        />
        <Box
          className={classes.backgroundBlur}
          style={{ left: "-120px", bottom: "350px" }}
        />
        <Box className={classes.contentWrapper}>
          <Box className={classes.textWrapper}>
            <Typography variant="h1" className={classes.title}>
              <span>{data.title_1}</span> <br></br>
              <span style={{ backgroundColor: "#f26522", padding: "0 8px" }}>
                {data.title_2}
              </span>
            </Typography>
            <Typography variant="body1" className={classes.paragraph}>
              {data.description}
            </Typography>
          </Box>
          <Box className={classes.videoWrapper}>
            <iframe
              width="100%"
              style={{ aspectRatio: "16/9" }}
              className={classes.videoReel}
              src={data.video}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Box>
        </Box>
      </Box>
    </Fade>
  )
}

export default VideoReel
