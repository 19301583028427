import { Box, Typography } from "@material-ui/core"
import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import AnniversaryLogo from "../../images/anniversary/anniversary_logo.svg"
import HeroBackground from "../../images/anniversary/hero.png"
import Fade from "react-reveal/Fade"

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    maxWidth: "1200px",
    width: "100%",
    display: "flex",
    justifyContent: "start",
    margin: "96px 48px",
    [theme.breakpoints.down("sm")]: {
      gap: "96px",
      flexDirection: "column-reverse",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "48px 24px",
    },
  },
  logo: {
    zIndex: "2",
    width: "60vw",
    maxWidth: "300px",
    [theme.breakpoints.down("sm")]: {
      width: "45vw",
    },
  },
  title: {
    paddingBottom: "32px",
    fontSize: "52px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "38px",
    },
  },
}))

const Hero = ({ data }) => {
  const classes = useStyles()

  return (
    <Box
      display="flex"
      position="relative"
      alignItems="center"
      justifyContent="center"
      paddingTop="125px"
    >
      <Fade delay={600} duration={4000}>
        <img
          src={HeroBackground}
          alt="background"
          style={{
            position: "absolute",
            top: "0",
            width: "100vw",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </Fade>
      <Box className={classes.contentWrapper}>
        <Box
          width="100%"
          style={{
            zIndex: "2",
          }}
        >
          <Fade duration={2000}>
            <Typography variant="h1" className={classes.title}>
              <span>{data.title_1}</span>
              <br></br>
              <span style={{ backgroundColor: "#f26522", padding: "0 8px" }}>
                {data.title_2}
              </span>
            </Typography>
            <Typography variant="body1">
              <span>{data.description_1}</span>
              <br></br>
              <span>{data.description_2}</span>
            </Typography>
          </Fade>
        </Box>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Fade delay={200} duration={3000}>
            <img
              src={AnniversaryLogo}
              alt="logo"
              className={classes.logo}
            ></img>
          </Fade>
        </Box>
      </Box>
    </Box>
  )
}

export default Hero
