import { Box, Typography } from "@material-ui/core"
import React from "react"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    maxWidth: "1200px",
    display: "flex",
    alignItems: "start",
    justifyContent: "center",
    margin: "96px 48px",
    gap: "48px",
    [theme.breakpoints.down("sm")]: {
      gap: "16px",
      flexDirection: "column",
      margin: "64px 24px",
    },
  },
  textWrapper: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}))

const Paragraph = ({ title, paragraph }) => {
  const classes = useStyles()

  return (
    <Box className={classes.contentWrapper}>
      <Box className={classes.textWrapper}>
        <Typography variant="h1">{title}</Typography>
      </Box>
      <Box className={classes.textWrapper}>
        <Typography>{paragraph}</Typography>
      </Box>
    </Box>
  )
}

export default Paragraph
