import { Box, Typography } from "@material-ui/core"
import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Fade from "react-reveal/Fade"

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    width: "100vw",
    justifyContent: "center",
    backgroundColor: "#0C0D0D",
  },
  dataWrapper: {
    marginBottom: "16px",
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    gridGap: "64px",
    maxWidth: "1200px",
    padding: "48px 0",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      gridGap: "32px",
    },
  },
  data: {
    fontSize: "52px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "38px",
    },
  },
}))

const Data = ({ data }) => {
  const classes = useStyles()

  return (
    <Fade delay={300} duration={2000}>
      <Box className={classes.wrapper}>
        <Box className={classes.dataWrapper}>
          {data.map(item => (
            <Fade top>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                width="150px"
              >
                <Typography variant="h1" className={classes.data}>
                  {item.data}
                </Typography>
                <Typography variant="caption" style={{ textAlign: "center" }}>
                  {item.title}
                </Typography>
              </Box>
            </Fade>
          ))}
        </Box>
      </Box>
    </Fade>
  )
}

export default Data
