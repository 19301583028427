import { Box, Button, Typography } from "@material-ui/core"
import React from "react"
import BookImage from "../../images/anniversary/anniversary_book.png"
import { Parallax } from "react-parallax"
import Paragraph from "./paragraph"
import { Document, Page, pdfjs } from "react-pdf"
import Bookpdf from "../../images/anniversary/book-compressed.pdf"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import { makeStyles } from "@material-ui/core/styles"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const useStyles = makeStyles(theme => ({
  prevButton: {
    position: "absolute",
    left: "20px",
    top: "48%",
    [theme.breakpoints.down("sm")]: {
      top: "40%",
    },
    margin: "auto",
    zIndex: "999",
    width: "40px",
    height: "40px",
    padding: "10px",
    minWidth: "auto",
    background: "#0009",
    "&:hover": {
      background: "#2229",
    },
  },
  nextButton: {
    position: "absolute",
    right: "20px",
    top: "48%",
    [theme.breakpoints.down("sm")]: {
      top: "40%",
    },
    margin: "auto",
    zIndex: "999",
    width: "40px",
    height: "40px",
    padding: "10px",
    minWidth: "auto",
    background: "#0009",
    "&:hover": {
      background: "#2229",
    },
  },
  flipbook: {
    width: "100vw",
    maxWidth: "1200px",
    marginBottom: "48px",
  },
  page: {
    display: "flex",
    width: "100vw",
    maxWidth: "1200px",
    justifyContent: "center",
    "& .react-pdf__Page__canvas": {
      width: "100vw !important",
      maxWidth: "1200px",
      height: "auto !important",
    },
    "& .react-pdf__Page__textContent": {
      width: "100vw !important",
      maxWidth: "1200px",
      height: "auto !important",
    },
  },
  bookImage: {
    height: "500px",
    width: "100vw",
    [theme.breakpoints.down("sm")]: {
      height: "50vw",
    },
  },
}))

const Book = ({ data }) => {
  const classes = useStyles()

  const [numPages, setNumPages] = React.useState(null)
  const [pageNumber, setPageNumber] = React.useState(1)

  const handleNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1)
    }
  }
  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1)
    }
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      marginBottom="64px"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        overflow="hidden"
        width="100%"
      >
        <Parallax bgImage={BookImage} strength={500}>
          <Box className={classes.bookImage} />
        </Parallax>
      </Box>
      <Paragraph title={data.title_1} paragraph={data.description_1} />
      <Box position="relative" className={classes.flipbook}>
        <Document
          file={Bookpdf}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={console.error}
          className={classes.flipbook}
        >
          <Page pageNumber={pageNumber} className={classes.page} />
        </Document>
        <Typography
          variant="caption"
          style={{
            position: "absolute",
            bottom: "10px",
            width: "100%",
            textAlign: "center",
            color: "#fff",
            textShadow: "0px 0px 3px #000b",
          }}
        >
          {pageNumber}/{numPages}
        </Typography>
        <Button
          variant="contained"
          onClick={handlePreviousPage}
          className={classes.prevButton}
        >
          <ChevronLeftIcon color="primary" />
        </Button>
        <Button
          variant="contained"
          onClick={handleNextPage}
          className={classes.nextButton}
        >
          <ChevronRightIcon color="primary" />
        </Button>
      </Box>
      <Paragraph title={data.title_2} paragraph={data.description_2} />
      <Paragraph title={data.title_3} paragraph={data.description_3} />
    </Box>
  )
}

export default Book
