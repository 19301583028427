import React from "react"
import Layout from "../components/layout/layout-home"
import Hero from "../components/anniversary/hero"
import Data from "../components/anniversary/data"
import VideoReel from "../components/anniversary/videoreel"
import Book from "../components/anniversary/book"
import { graphql, useStaticQuery } from "gatsby"

const AnniversaryPage = () => {
  const {
    strapi: {
      d20Anniversary: {
        AnniversaryBook,
        AnniversaryData,
        AnniversaryHero,
        AnniversaryVideo,
      },
    },
  } = useStaticQuery(graphql`
    {
      strapi {
        d20Anniversary {
          AnniversaryHero {
            title_1
            title_2
            description_1
            description_2
          }
          AnniversaryData {
            title
            data
          }
          AnniversaryVideo {
            title_1
            title_2
            description
            video
          }
          AnniversaryBook {
            title_1
            description_1
            title_2
            description_2
            title_3
            description_3
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Hero data={AnniversaryHero} />
      <Data data={AnniversaryData} />
      <VideoReel data={AnniversaryVideo} />
      <Book data={AnniversaryBook} />
    </Layout>
  )
}

export default AnniversaryPage
